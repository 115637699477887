<template>
  <!-- Skeleton Loaders -->
  <v-row v-if="brands.length === 0" dense>
    <v-col  cols="12" md="3" v-for="index in 4" :key="index" style="padding: 8px">
      <v-skeleton-loader
        type="card"
        elevation=2
      ></v-skeleton-loader>
    </v-col>
  </v-row>
  <!-- Brand Cards -->
  <v-row v-else dense>
    <v-col cols="12" md="3" v-for="brand in brands" :key="brand.brand_id">
      <BrandCard
      :brand="brand"
      :access="access"
      v-on="$listeners"
      ></BrandCard>
    </v-col>
  </v-row>
</template>

<script>
import BrandCard from '@/components/brands/BrandCard.vue';
export default {
  props: {
    brands: Array,
    access: Object,
  },
  components: {
    BrandCard,
  }
}
</script>