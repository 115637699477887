import { mapActions } from 'vuex';
export const brandsCore = {
  computed: {
    brands() {
      return this.$store.getters.getBrands;
    },
  },
  created: function() {
    if (!this.$store.getters.getMixinActive('brands')) {
      this.$store.dispatch("getBrands");
    }
    this.$store.commit('setMixinActive',  [ true, 'brands'] );
  },
};

export const brandsLogic = {
  methods: {
    ...mapActions({
      uploadBrand: 'uploadBrand',
      updateBrand: 'updateBrand',
      deleteBrand: 'deleteBrand',
    }),
  },
};

export const brandsProduct = {
  methods: {
    closeBrandPopupAfterCreate() {
      const brand_select = this.$refs.brand_select;
      if (brand_select) {
        brand_select.blur();
      }
    }
  }
};
