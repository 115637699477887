<template>
  <v-card hover outlined class="rounded-lg" style="cursor: auto">
    <v-img
      :src="brand.image_url"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="200px"
    ></v-img>
    <v-card-title class="justify-center">
      {{ brand.name }}

      <div style="position: absolute; right: 0; top: 0;">
      <!-- Vertical Dots Menu -->
      <v-menu bottom right absoloute>
        <!-- Button with vertical dots -->
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- Edit Button -->
          <v-list-item v-if="access.update_access">
            <v-btn block text @click.stop="showBrandDialog = !showBrandDialog">
              Edit Brand
            </v-btn>
            <BrandEditCard
            :brand="brand"
            :show.sync="showBrandDialog"
            :isUpdate="true"
            ></BrandEditCard>
          </v-list-item>
          <!-- Audit Log Button -->
          <v-list-item>
            <v-btn block text @click.stop="showAuditLog = true; getLogs()">
              Audit Log
            </v-btn>
            <AuditLogDialog
              :show.sync="showAuditLog"
              :logs="auditLogs"
            ></AuditLogDialog>
          </v-list-item>
          <!-- Delete Button -->
          <v-list-item v-if="access.delete_access">
            <v-btn text block color="error" @click="$emit('delete', brand)">
              Delete Brand
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      </div>
    </v-card-title>

    <div v-if="additional_info">
      <div v-for="(info, i) in additional_info" :key="i">
        <div v-if="info.show">
          <v-divider></v-divider>
          <v-card-text class="text-center" style="padding: 4px 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ info.title }}: {{ info.info }}</v-card-text>
        </div>
      </div>
    </div>
  </v-card>  
</template>

<script>
import { auditLogsLogic } from '@/mixins/auditLogsMixin.js'
import BrandEditCard from '@/components/brands/BrandEditCard.vue'
import AuditLogDialog from '@/components/auditLogs/AuditLogDialog.vue'
export default {
  mixins: [auditLogsLogic],
  props: {
    brand: Object,
    additional_info: Array,
    access: Object,
  },
  data() {
    return {
      showBrandDialog: false,
      showAuditLog: false,
      auditLogs: [],
    }
  },
  components: {
    BrandEditCard,
    AuditLogDialog,
  },
  methods: {
    async getLogs() {
      this.auditLogs = await this.getAuditLogs(this.brand.brand_id, this.$route.path.split('/')[1]);
    }
  }
};
</script>