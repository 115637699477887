<template>
  <component :is="flat ? 'div' : 'v-dialog'" v-model="show" max-width="700" @click:outside="$emit('update:show', false)" @keydown.esc="$emit('update:show', false)">
    <v-card>
      <v-form ref="brand_card" @submit="(e) => e.preventDefault()">
        <v-container>
          <v-row justify="center">
            <v-card-title v-if="isUpdate" class="headline">Edit Brand</v-card-title>
            <v-card-title v-else class="headline">Add New Brand</v-card-title>
          </v-row>
          <v-container justify="space-around">
            <v-row justify="center">
              <VueFileAgent 
                v-model="new_brand.image"
                :multiple="false"
                :accept="'image/*'"
              ></VueFileAgent>
            </v-row>
            <v-row>
              <v-text-field v-if="isUpdate" v-model="brand.name" label="Name" v-on:keyup.enter="validate" :rules="rules.name" required></v-text-field>
              <v-text-field v-else v-model="new_brand.name" label="Name" v-on:keyup.enter="validate" :rules="rules.name" required></v-text-field>
            </v-row>
            <v-row justify="center">
              <v-btn class="rounded-lg" outlined color="primary" @click="validate">{{ isUpdate ? 'Update' : 'Confirm' }}</v-btn>
            </v-row>
          </v-container>
        </v-container>
      </v-form>
    </v-card>
  </component>
</template>

<script>
import { VDialog } from 'vuetify/lib';
import { brandsLogic } from "@/mixins/brandsMixin.js";
export default {
  mixins: [brandsLogic],
  components: {VDialog},
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    brand: Object,
    show: Boolean,
    isUpdate: Boolean,
  },
  data() {
    return {
      new_brand: {
        name: "",
        image: null
      },
      rules: {
        name: [v => !!v || 'Name is required']
      }
    }
  },
  methods: {
    async validate() {
      if (this.$refs.brand_card.validate()) {
        if (this.isUpdate) {
          this.updateBrand({ brand_id: this.brand.brand_id, name: this.brand.name, image: this.new_brand.image });
        } else {
          let brand_id = await this.uploadBrand({ name: this.new_brand.name, image: this.new_brand.image });

          // If creating brand on the fly (in Create Product or Product Details)
          // Update the brand to the new brand ID
          this.$emit('update:product_brand', brand_id);
          // Close the select popup
          this.$emit('close_brand_select');
          // Change tab if on first setup
          this.$emit('update:selected_tab', 2);
        }
        this.$emit('update:show', false);
        this.reset();
      }
    },
    reset() {
      this.new_brand.name = "";
      this.new_brand.image = null;
      if (this.$refs.brand_card) {
        this.$refs.brand_card.resetValidation();
      }
    }
  }
};
</script>