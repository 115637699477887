<template>
  <v-container fluid>
    <Header 
    title="Your Brands"
    :options="header_options"
    ></Header>

    <!-- Add Brand Dialog -->
    <BrandEditCard
      :show.sync="showCreateBrand"
      :isUpdate="false"
    ></BrandEditCard>

    <ActionDialog ref="delete_dialog" title="Deleting Brand" color="error">
      <template #default="{ options }">
        <span class="error--text" v-html="options.data.brand?.product_count > 0 ?
          `Are you sure you want to delete this brand?<br>Warning! There ${options.data.brand?.product_count === 1 ? 'is' : 'are'} <b>${options.data.brand?.product_count}</b> product${options.data.brand?.product_count === 1 ? '' : 's'} using this brand.<br><br>Please select a brand to migrate these products to.` :
          `Are you sure you want to delete this brand?<br>Warning! This process cannot be undone.`"></span>
        <BrandSelect
          v-if="options.data.brand?.product_count > 0"
          v-model="options.data.brand.new_brand_id"
          :brands="brands.filter(x => x.brand_id !== options.data.brand.brand_id)"
          :access="access"
          class="mt-3"
          hide_details="auto"
        ></BrandSelect>
      </template>
    </ActionDialog>

    <BrandCards
      v-if="brands.length !== 0"
      :brands="brands"
      :access="access"
      @delete="brand => $refs.delete_dialog.showDialog({ brand: brand }).then(data => deleteBrand(data.brand)).catch(() => {})"
    ></BrandCards>
    <BrandEditCard
      v-else
      :show="true"
      :isUpdate="false"
      :flat="true"
    ></BrandEditCard>
  </v-container>
</template>

<script>
import { brandsCore, brandsLogic } from "@/mixins/brandsMixin.js";
import BrandCards from '@/components/brands/BrandCards.vue';
import BrandEditCard from '@/components/brands/BrandEditCard.vue';
import ActionDialog from '@/components/ActionDialog.vue';
import Header from "@/components/Header.vue"
import BrandSelect from "@/components/brands/BrandSelect.vue"
export default {
  mixins: [brandsCore, brandsLogic],
  data() {
    return {
      access: this.$store.getters.getAccessLevel[this.$route.path.split('/')[1]],
      showCreateBrand: false,
    };
  },
  components: {
    Header,
    BrandEditCard,
    ActionDialog,
    BrandCards,
    BrandSelect,
  },
  computed: {
    header_options() {
      let options = [];
      if (this.brands.length !== 0) {
        options.push(
          {
            'title': 'Create Brand',
            'icon': 'mdi-plus',
            'disabled': !this.access.create_access,
            'action': () => {this.showCreateBrand = !this.showCreateBrand}
          }
        )
      }
      return options;
    }
  }
};
</script>