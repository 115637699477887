<template>
  <v-select
    ref="brand_select"
    :items="brands"
    label="Brand"
    outlined
    :value="value"
    item-text="name"
    item-value="brand_id"
    :hide-details="hide_details"
    :rules="[v => !!v || 'Brand is required']"
    :readonly="!access.update_access"
    @input="$emit('input', $event)"
  >
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @mousedown.prevent
        @click="showAddNewBrand = !showAddNewBrand"
      >
        <v-icon>mdi-plus</v-icon>
        <v-list-item-content>
          <v-list-item-title>
            Create new Brand
          </v-list-item-title>
          <BrandEditCard
            :show.sync="showAddNewBrand"
            :isUpdate="false"
            @close_brand_select="$refs.brand_select.blur()"
            @update:product_brand="$emit('input', $event)"
          ></BrandEditCard>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
import BrandEditCard from '@/components/brands/BrandEditCard.vue'
export default {
  components: {
    BrandEditCard,
  },
  props: {
    brands: Array,
    access: Object,
    value: Number,
    hide_details: {
      default: false,
    }
  },
  data() {
    return {
      showAddNewBrand: false,
    }
  }
}
</script>